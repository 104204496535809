<template>
    <div>
        <h4 class="bold text-uppercase mb-3">
            <span @click="$router.go(-1)" class="font-weight-bold hand">
                <i class="fas fa-chevron-left mx-1"></i>
                {{ $route.name }}
            </span>
        </h4>
        <div class="row mt-3">
            <div class="col-md-8 col-12">
                <div class="card card-gray">
                    <div class="card-body">
                        <div class="form-group row" v-for="(account, index) in sitePayment" :key="index">
                            <label class="col-sm-4 col-form-label bold">Tài khoản ngân hàng số: {{ index + 1 }}</label>
                            <div class="col-sm">
                                <div class="card card-table">
                                    <div class="card-body">
                                        <h6>
                                            Ngân hàng: <span class="bold">{{ account.name }}</span>
                                        </h6>
                                        <h6>
                                            Chủ TK: <span class="bold">{{ account.account_name }}</span>
                                        </h6>
                                        <h6>
                                            STK: <span class="bold">{{ account.account_number }}</span>
                                        </h6>
                                        <h6>
                                            Chi nhánh: <span class="bold">{{ account.branch }}</span>
                                        </h6>
                                        <button
                                            @click="setEditBank(index)"
                                            type="button"
                                            class="btn btn-primary btn-xs mr-1 px-3"
                                            data-toggle="modal"
                                            href="#modal-bank-account"
                                        >
                                            Sửa
                                        </button>
                                        <button
                                            @click="deleteBankAccount(index)"
                                            type="button"
                                            class="btn btn-danger btn-xs mr-1 px-3"
                                        >
                                            Xóa
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-4 col-form-label font-bold"></label>
                            <div class="col-sm-8">
                                <button
                                    data-toggle="modal"
                                    href="#modal-bank-account"
                                    class="btn mt-3 form-control bold btn-dark-blue"
                                    type="submit"
                                >
                                    Thêm tài khoản ngân hàng
                                </button>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-4 col-12">
                                <h6 class="bold">Cài đặt nội dung chuyển khoản:</h6>
                            </div>
                            <div class="col">
                                <input
                                    type="text"
                                    placeholder="Cài đặt nội dung chuyển khoản"
                                    class="form-control"
                                    v-model="siteUpdate.payment_syntax"
                                />
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-4 col-12">
                                <h6 class="bold">Cài đặt hướng dẫn hiển thị cho khách hàng:</h6>
                            </div>
                            <div class="col">
                                <textarea
                                    v-model="siteUpdate.payment_note"
                                    class="form-control"
                                    rows="5"
                                    placeholder="Nhập nội dung hướng dẫn hiển thị cho khách hàng"
                                ></textarea>
                                <button
                                    @click="updateSiteInfo()"
                                    class="btn form-control btn-lg bold btn-dark-blue form-control mt-3"
                                    type="submit"
                                >
                                    Lưu thông tin
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-12">
                <div class="card card-gray-2">
                    <div class="card-body">
                        <div class="">
                            <h6>
                                <i class="fa fa-info mr-1"></i>
                                Bạn có thể cài đặt thông tin tài khoản ngân hàng, hướng dẫn nạp tiền, cú pháp của trang
                                tại đây.
                            </h6>
                            <h6>
                                Bạn có thể nhập thêm cú pháp: {username} để hiển thị username người dùng. Ví dụ: NAPTIEN
                                {username}
                            </h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            id="modal-bank-account"
            class="modal fade"
            tabindex="-1"
            role="dialog"
            aria-labelledby="bankAccount"
            aria-hidden="true"
            style="display: none;"
        >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">Thêm Tài Khoản Ngân Hàng</h4>
                    </div>
                    <form @submit.stop.prevent="updateBankAccount">
                        <div class="modal-body py-3">
                            <div class="form-group">
                                <label>Ngân Hàng:</label>
                                <input type="text" v-model="bank.name" class="form-control" />
                            </div>
                            <div class="form-group">
                                <label>Tên Chủ Tài Khoản:</label>
                                <input type="text" v-model="bank.account_name" class="form-control" />
                            </div>
                            <div class="form-group">
                                <label>Số Tài Khoản:</label>
                                <input type="text" min="1" v-model="bank.account_number" class="form-control" />
                            </div>
                            <div class="form-group">
                                <label>Chi Nhánh:</label>
                                <input type="text" v-model="bank.branch" class="form-control" />
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn bg-orange waves-effect" data-dismiss="modal">
                                Hủy bỏ
                            </button>
                            <button type="submit" :class="['btn btn-primary waves-effect waves-light']">
                                Cập Nhật
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { updateSiteInfo } from "../../../api/admin-agency-lv2"
import { catchError } from "../../../helpers"
export default {
    name: "site-price",
    components: {},
    data() {
        return {
            siteUpdate: {},
            sitePayment: [],
            bank: {},
            bankEditId: null
        }
    },
    computed: {
        user() {
            return this.$store.state.user.user
        },
        tools() {
            return this.$store.state.tools.tools
        },
        isMobile() {
            return this.$store.state.app.isMobile
        },
        reload() {
            return this.$store.state.app.reload
        },
        currency() {
            return this.$store.state.agency.site.currency || "Xu"
        },
        site() {
            return this.$store.state.agency.site
        },
        agency() {
            return this.$store.state.agency
        }
    },
    created() {
        this.siteUpdate = Object.assign({}, this.site)
        this.sitePayment = this.site.payment ? JSON.parse(this.site.payment) : []
    },
    methods: {
        setEditBank: function(id) {
            this.bank = Object.assign({}, this.sitePayment[id])
            this.bankEditId = id
        },
        deleteBankAccount: async function(id) {
            this.sitePayment.splice(id, 1)
            this.siteUpdate.payment = JSON.stringify(this.sitePayment)
            this.updateSiteInfo()
        },
        updateBankAccount: async function() {
            if (this.bankEditId === null) {
                let paymentJSON = this.sitePayment ? this.sitePayment : []
                paymentJSON.push({
                    name: this.bank.name,
                    account_number: this.bank.account_number,
                    account_name: this.bank.account_name,
                    branch: this.bank.branch
                })
                let paymentText = JSON.stringify(paymentJSON)
                this.siteUpdate.payment = paymentText
            } else {
                let editData = {
                    name: this.bank.name,
                    account_number: this.bank.account_number,
                    account_name: this.bank.account_name,
                    branch: this.bank.branch
                }
                this.sitePayment.splice(this.bankEditId, 1, editData)
                this.bankEditId = null
                this.siteUpdate.payment = JSON.stringify(this.sitePayment)
            }
            this.updateSiteInfo()
        },
        updateSiteInfo: async function() {
            let data = await updateSiteInfo(this.siteUpdate)
            if (data.status === 200 && data.success) {
                this.$swal("Thành Công", data.message, "success")
                // eslint-disable-next-line no-undef
                $("#modal-bank-account").modal("hide")
                this.$store.dispatch("GET_SITE_AGENCY_INFO", this.agency.agency_domain)
            } else this.$swal("Lỗi", catchError(data), "error")
        }
    }
}
</script>
